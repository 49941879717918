import logo from './logo.svg';
import './App.css';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Admin from './pages/admin.jsx';
import Home from './pages/home.jsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    // Define routes directly without <Routes> wrapper
    <>
      <Route path="/" element={<Home />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/admin/:section" element={<Admin />} />
    </>
  )
);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
