import React, { useState, useEffect } from "react";

import {
  Dialog,
  Alert,
  DialogActions,
  Menu,
  MenuItem,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Avatar,
  Button,
  Modal,
  CircularProgress,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
// import axios from 'axios';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./admin.css";
// import  Upload from '../../assets/upload.png'
import axios from "axios";

export default function User() {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("auth_token"); // Get your token from local storage
      try {
        const response = await axios.get(
          "https://lukzbetbackend.gamingesupport.xyz/api/admin/user-details",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token for authorization
            },
          }
        );
        setUserData(response.data.data); // Set the user data from response
      } catch (err) {
        if (err.response) {
          setError(err.response.data.error || "An error occurred"); // Handle error messages
        } else {
          setError("Network error");
        }
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchUserData();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: "20px",
        width: "70%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "100px",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "black",
          fontWeight: "bold",
          textAlign: "start",
          width: "70%",
          marginBottom: "50px",
          marginLeft: "40px",
        }}
      >
        All Users
      </Typography>
      <Grid container spacing={2} justifyContent="start">
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 400, overflow: "auto" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  User Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Total Amount
                </TableCell>
                {/* <TableCell align="center" sx={{fontWeight:"bold"}}>Referral Code</TableCell> */}
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  User Email
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    {error}
                  </TableCell>
                </TableRow>
              ) : (
                userData.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{user.name}</TableCell>
                    <TableCell align="center">{user.balance}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                  </TableRow>
                ))
              )}

              {/* {users.length > 0 ? users.map((userObj, index) => {
                const user = userObj.user;
                if(user.Role != 1){
                  return (
                    <TableRow key={user.Id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align='center' component="th" scope="row">{user.Name}</TableCell>
                      <TableCell align="center">{user.Phone}</TableCell>
                      <TableCell align="center">{user.Referral_code}</TableCell>
                      <TableCell align="center">{user.Email}</TableCell>
                  </TableRow>
                  );
                }
              }) : <Typography>No users available</Typography>} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
