import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Box, Button } from "@mui/material";
// import axios from 'axios';
// import BgImage from '../../../public/assets/Bg-image.jpg';
// import Footer from '../../components/Footer/footer';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate, useParams } from "react-router-dom";
import "./admin.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import Swal from "sweetalert2";

export default function TransactionDeposits() {
  // const [deposits, setDeposits] = useState([]);

  // useEffect(() => {
  //   axios.get('https://lukzbetbackend.gamingesupport.xyz/api/Get_Admin_Deposit_Data', {
  //     headers: {
  //       'Authorization': `Bearer ${localStorage.getItem('token')}`
  //     }
  //   })
  //   .then(response => {
  //     // Filter the deposits to include only those with status 'approved'

  //     const approvedDeposits = response.data.data;
  //     setDeposits(approvedDeposits);
  //   })
  //   .catch(error => {
  //     console.error('Error fetching deposits:', error.response ? error.response.data : error.message);
  //   });
  // }, []);

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
  // };

  const [withdraw, setWithdraw] = useState([]);
  const token = localStorage.getItem("auth_token");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deposit, setDeposit] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedDepositId, setSelectedDepositId] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [actionType, setActionType] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
    setSelectedDepositId(null);
    setActionType(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const approveWithdraw = async (withdrawId) => {
    try {
      const response = await axios.put(
        `https://lukzbetbackend.gamingesupport.xyz/api/admin/deposit/${withdrawId}/approved`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with the user's auth token
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        // You can also show a success message on the frontend
      } else {
        console.error("Failed to update withdraw status");
      }
    } catch (error) {
      console.error(
        "Error approving withdrawal:",
        error.response?.data?.message || error.message
      );
    }
  };

  const withdrawalReject = async (depositId) => {
    setLoading(true);
    setError(null);
    // setSuccessMessage(null);

    try {
      const response = await axios.put(
        `https://lukzbetbackend.gamingesupport.xyz/api/admin/deposit/${depositId}/rejected`, // Change the endpoint to reject
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        // setSuccessMessage(`Deposit ${depositId} rejected successfully.`);
        // Optionally, reload the deposit data after rejection or update the UI
      }
    } catch (err) {
      setError("Failed to reject deposit. Please try again.");
    } finally {
      setLoading(false);
    }
    closeConfirmDialog();

    window.location.reload(); // Reload the page to refresh the data
  };

  const openConfirmDialog = (id, type) => {
    setSelectedDepositId(id);

    setActionType(type);
    alert(selectedDepositId);

    if (type === "approve") {
      Swal.fire({
        title: "Are you sure you want to approve?",
        text: "You will not be able to recover this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "green",
        confirmButtonText: "Yes, approve it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Delay the success message and approval logic by 2 seconds

          Swal.fire(
            "Approved!",
            "Your imaginary file has been approved.",
            "success"
          );
          setTimeout(() => {
            //window.location.reload();
          }, 2000);
          approveWithdraw(selectedDepositId);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
    }

    if (type === "reject") {
      Swal.fire({
        title: "Are you sure you want to reject?",
        text: "You will not be able to recover this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "red",
        confirmButtonText: "Yes, reject it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Delay the success message and approval logic by 2 seconds

          Swal.fire(
            "Rejected!",
            "Your imaginary file has been rejected.",
            "success"
          );
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          withdrawalReject(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
    }
  };

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const response = await axios.get(
          "https://lukzbetbackend.gamingesupport.xyz/api/admin/withdraw",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data);
        setWithdraw(response.data.data);
      } catch (err) {
        setError("Failed to fetch deposit details.");
      } finally {
        setLoading(false);
      }
    };

    fetchDeposits();
  }, [token]);

  return (
    <Box>
      <Box
        sx={{
          width: "800px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "50px",
          height: "100%",
        }}
      >
        <Box sx={{ width: "100%", marginBottom: "20px", marginTop: "-100px" }}>
          <Typography variant="h5" sx={{ color: "black", fontWeight: "bold" }}>
            All Withdraws
          </Typography>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ width: "140%", height: 500, overflow: "auto" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Bank Holder Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Account Number
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Bank
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Branch
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Amount
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {/* {packages.map((pkg) => ( */}

            {withdraw.map((withdraw) => (
              <TableRow key={withdraw.id}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "20%" }}
                  align="center"
                >
                  {withdraw.account_holder_name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "20%" }}
                  align="center"
                >
                  {withdraw.account_no}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "10%" }}
                  align="center"
                >
                  {withdraw.bank}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "10%" }}
                  align="center"
                >
                  {withdraw.branch}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "10%" }}
                  align="center"
                >
                  {withdraw.withdraw_amount}
                </TableCell>
                <TableCell align="center" sx={{ width: "25%" }}>
                  {withdraw.status === "Approved" ? (
                    <Button
                      disabled
                      sx={{
                        bgcolor: "#B4E380",
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      Approved
                    </Button>
                  ) : withdraw.status === "Rejected" ? (
                    <Button
                      disabled
                      sx={{
                        bgcolor: "#EE4E4E",
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      Rejected
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={() =>
                          openConfirmDialog(withdraw.id, "approve")
                        }
                        sx={{
                          ":hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                          alignItems: "center",
                          marginTop: "10px",
                          height: "30px",
                          backgroundColor: "green",
                          borderRadius: "5px",
                          fontSize: "10px",
                          color: "white",
                          marginRight: "10px",
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={() => openConfirmDialog(withdraw.id, "reject")}
                        sx={{
                          ":hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                          height: "30px",
                          marginTop: "10px",
                          backgroundColor: "red",
                          borderRadius: "5px",
                          fontSize: "10px",
                          color: "white",
                          marginRight: "10px",
                        }}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>

        {/* <Modal open={open} onClose={handleClose}>
              <Box sx={{position: 'absolute', top: '50%', border:"none" ,left: '50%', transform: 'translate(-50%, -50%)', width: 500, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '10px'}}>
                <Typography id="modal-title" variant="h6" component="h2">
                  Deposit Slip
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Deposit"
                      style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                    />
                  )}
                </Box>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={handleClose} sx={{ mt: 2, backgroundColor: 'red', color: 'white', ':hover': { backgroundColor: 'darkred' } }}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Modal> */}

        {/* <Dialog
                open={confirmOpen}
                onClose={closeConfirmDialog}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
              >
                <DialogTitle id="confirm-dialog-title">{"Confirm Action"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirm-dialog-description">
                    Are you sure you want to {actionType} this deposit?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeConfirmDialog} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      if (actionType === 'approve') {
                        handleApprove(selectedDepositId); // Call approve if action is 'approve'
                      } else if (actionType === 'reject') {
                        handleReject(selectedDepositId); // Call reject if action is 'reject'
                      }
                    }}
                    color="primary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog> */}
      </Box>
    </Box>
  );
}
