import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
// import axios from 'axios';
import { Container, Grid, Typography, Box, Button } from '@mui/material';
// import BgImage from '../../../public/assets/Bg-image.jpg';
// import Footer from '../../components/Footer/footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import './admin.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Deposits from './deposite';
// import Withdrawawl from './withdrawal';


export default function Notify() {
  

  const [selectedOption, setSelectedOption] = useState('deposits');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
  };



  return (
    <Box>
        <Box
          sx={{
            width: '100%',
            height: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // backgroundImage: `url(${BgImage})`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            
          }}
        >
          
          <Box
            sx={{
              width: '120%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              borderRadius: '20px',
            }}
          >

          {/* <Box sx={{ width: '100%', marginBottom: '20px', marginTop: '20px', display:"flex", flexDirection:"row" }}>
            <Box sx={{marginRight:"50px"}}>
              <input type="radio" id='deposits' name="transaction" onChange={handleOptionChange}/>
              <label htmlFor="deposits">Deposits</label>
            </Box>
            <Box>
              <input type="radio" id="withdraws" name="transaction" onChange={handleOptionChange}/>
              <label htmlFor="withdraws">Withdraw</label>
            </Box>
          </Box> */}

          <Box sx={{marginLeft:"-50px"}}>
            {selectedOption === 'deposits' && <Deposits />}
            {/* {selectedOption === 'withdraws' && <Withdrawawl />} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
