import React, { useState, useEffect } from 'react';

const ImageSlider = ({ images, overlays }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opacity, setOpacity] = useState(0); // State for overlay opacity

  const nextSlide = () => {
    setOpacity(0); // Start fading out the overlay
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setOpacity(1); // Start fading in the overlay after slide change
    }, 500); // Match with fade out duration
  };

  const prevSlide = () => {
    setOpacity(0); // Start fading out the overlay
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
      setOpacity(1); // Start fading in the overlay after slide change
    }, 500); // Match with fade out duration
  };

  useEffect(() => {
    const autoSlide = setInterval(nextSlide, 3000); // Auto slide every 3 seconds
    return () => clearInterval(autoSlide);
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <button onClick={prevSlide} style={buttonStyle}>
        &#10094;
      </button>
      <img
        src={images[currentIndex]}
        alt="slider"
        style={{
          width: '100%',
          height: '50vh',
          borderRadius: '10px',
          boxShadow: '2px 2px 50px #000',
          objectFit: 'cover',
        }}
      />
      <button onClick={nextSlide} style={{ ...buttonStyle, right: '0' }}>
        &#10095;
      </button>

      {/* Overlay for text and button */}
      <div style={{ ...overlayStyle, opacity: opacity, transition: 'opacity 0.5s ease-in-out' }}>
        <div style={textStyle}>{overlays[currentIndex].text}</div>
        <div style={textStyle1}>{overlays[currentIndex].text1}</div>
        <button
          style={buttonOverlayStyle}
          onClick={() => {
            const token = localStorage.getItem("authToken"); // Check for token in localStorage
            if (token) {
              window.location.href = overlays[currentIndex].link; // Redirect if token exists
            } else {
              alert("Error: No token found. Please log in first."); // Show alert if no token
            }
          }}
        >
          {overlays[currentIndex].buttonText}
        </button>
      </div>
    </div>
  );
};

// Button style for navigation (left/right)
const buttonStyle = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0,0,0,0.5)',
  color: 'white',
  border: 'none',
  fontSize: '24px',
  padding: '10px',
  cursor: 'pointer',
};

// Overlay style for text and button on top of the image
const overlayStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  padding: '20px',
  borderRadius: '10px',
  width: '40%',
  height: 'auto', 
};

// Text styling
const textStyle = {
  fontSize: '40px',
  padding: '10px 10px',
  borderRadius: '10px',
};

const textStyle1 = {
  fontSize: '30px',
  padding: '10px 20px',
  borderRadius: '10px',
  marginBottom: '10px',
  color:"yellow"
};


// Button styling on top of the image
const buttonOverlayStyle = {
  padding: '10px 20px',
  fontSize: '16px',
  borderRadius: '5px',
  border: 'none',
  backgroundColor: '#4CAF50',
  color: 'white',
  cursor: 'pointer',
};

// Export ImageSlider as default
export default ImageSlider;
